.App {
  text-align: left;
}

p {
  font-family: "Open Sans", sans-serif;
  color: rgb(77, 77, 77);
  font-size: 13px;
  line-height: 1.25rem;
}

h1.header-text {
  color: rgb(77, 77, 77);
  font-family: "noticia text", serif;
  font-size: 43px;
  font-weight: "bold";
  margin-bottom: 0;
  margin-top: 0;
  line-height: revert;
}

h2.subheader-text {
  color: rgb(77, 77, 77);
  font-family: "noticia text", serif;
  font-size: 18px;
  font-weight: bold;
  line-height: revert;
  margin-bottom: 64px;
  margin-top: 0;
}
  
#profile-image {
    width: 380px;
    height: 380px;
  }

#contact-form {
  width: 50%;
  margin: auto;
  padding-bottom: 2rem;
}

#contact-form legend h1.header-text {
  color: white;
  font-size: 2rem;
}


#contact-form fieldset {
  margin: 0;
  padding: 1rem 1.5rem;
}

#contact-form label {
  width: 100%;
  display: block;
  font-size: 1rem;
  margin-bottom: .5em;
  padding-top: .2em;
  display: flex;
  align-items: baseline;
}

#contact-form input, #contact-form textarea {
  font-size: .85rem;
  display: block;
  width: calc(100% - 1.5rem);
  border: 1px solid rgba(0,0,0,0.2);
  background-color: rgba(255,255,255,0.9);
  padding: .75em 1em;
  margin-bottom: 1.5em;
}

#contact-form-message {
  height: 6rem;
}
  
#contact-form button {
  background-color: #428BCA;
  border: 0px;
  border-radius: .5rem;
  color: white;
  float: right;
  font-size: 1.25em;
  padding: .5rem 1rem;
  text-transform: uppercase;
  
}


#contact-form button:hover,
#contact-form button:focus {
  background-color: rgba(0,0,0,.8);
  color: white;
}

footer {
  align-items: "center";
  background: rgb(0, 45, 88);
  color: white !important;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  margin-top: 2.5rem;
  padding-bottom: 2.5rem;
  padding-top: 1rem;
}
  
/* TODO: Media Queries: https://www.w3schools.com/howto/howto_css_media_query_breakpoints.asp */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  p {
    font-size: 14px;
  }
  
  #contact-form {
    width: 100%;
  }

  #profile-image {
    width: 350px;
    height: 350px;
  }
}
